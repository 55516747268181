import React from 'react';
import clsx from 'clsx';

type Props = {
  className: string;
};

function MarkEmailRead({ className }: Props) {
  return (
    <svg className={clsx(className)} viewBox="0 0 260 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.15"
        d="M157.786 32.6749L32.3331 158.127C14.476 175.984 14.476 204.936 32.3331 222.794C50.1902 240.651 79.1423 240.651 96.9993 222.794L222.452 97.3411C240.309 79.484 240.309 50.5319 222.452 32.6748C204.595 14.8178 175.643 14.8178 157.786 32.6749Z"
        fill="#F7AFA3"
      />
      <path
        d="M227.882 95.4542V175.785C227.882 178.945 226.628 181.976 224.396 184.212C222.163 186.447 219.135 187.706 215.975 187.711L70.2741 187.802C68.7034 187.802 67.1482 187.492 65.6977 186.889C64.2473 186.287 62.9302 185.403 61.8221 184.29C60.7141 183.177 59.8368 181.856 59.2407 180.403C58.6447 178.949 58.3415 177.393 58.3488 175.822L58.678 96.1126C58.6924 92.972 59.9451 89.9638 62.1642 87.7413C64.3833 85.5188 67.3895 84.2614 70.5301 84.2422L215.884 83.474C217.461 83.4643 219.024 83.7674 220.483 84.3658C221.941 84.9642 223.267 85.846 224.383 86.9602C225.499 88.0743 226.383 89.3987 226.983 90.8567C227.584 92.3147 227.889 93.8773 227.882 95.4542Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M126.816 135.8L155.873 105.202L159.852 108.98L130.795 139.578L126.816 135.8Z"
          fill="#F5A8A0"
        />
        <path
          opacity="0.3"
          d="M139.032 135.852L157.393 115.961L161.425 119.683L143.064 139.574L139.032 135.852Z"
          fill="#F5A8A0"
        />
      </g>
      <path
        d="M160.317 75.536C157.97 75.536 155.675 74.8398 153.723 73.5354C151.771 72.2311 150.249 70.3772 149.351 68.2081C148.452 66.0391 148.217 63.6523 148.675 61.3497C149.133 59.0471 150.264 56.9319 151.924 55.2718C153.584 53.6117 155.699 52.4812 158.002 52.0231C160.304 51.5651 162.691 51.8002 164.86 52.6986C167.029 53.5971 168.883 55.1185 170.187 57.0706C171.492 59.0227 172.188 61.3178 172.188 63.6655C172.183 66.8123 170.931 69.8287 168.706 72.0538C166.481 74.2789 163.464 75.5311 160.317 75.536ZM160.317 57.2456C159.055 57.2456 157.821 57.62 156.771 58.3214C155.721 59.0228 154.903 60.0197 154.42 61.1861C153.937 62.3525 153.81 63.636 154.057 64.8743C154.303 66.1125 154.911 67.2499 155.804 68.1426C156.696 69.0354 157.834 69.6433 159.072 69.8896C160.31 70.1359 161.594 70.0095 162.76 69.5264C163.927 69.0432 164.924 68.225 165.625 67.1753C166.326 66.1256 166.701 64.8914 166.701 63.6289C166.691 61.9406 166.015 60.3245 164.819 59.1324C163.624 57.9402 162.006 57.2687 160.317 57.2639V57.2456Z"
        fill="#F5A8A0"
      />
      <path
        d="M140.765 74.2C142.917 74.2 144.661 72.4558 144.661 70.3042C144.661 68.1526 142.917 66.4083 140.765 66.4083C138.613 66.4083 136.869 68.1526 136.869 70.3042C136.869 72.4558 138.613 74.2 140.765 74.2Z"
        fill="#F5A8A0"
      />
      <path
        d="M77.0597 201.645C79.2113 201.645 80.9555 199.901 80.9555 197.75C80.9555 195.598 79.2113 193.854 77.0597 193.854C74.9081 193.854 73.1638 195.598 73.1638 197.75C73.1638 199.901 74.9081 201.645 77.0597 201.645Z"
        fill="#D5DFEB"
      />
      <path
        d="M95.7709 213.699C93.8283 213.699 91.9293 213.123 90.3141 212.044C88.6989 210.965 87.44 209.431 86.6966 207.636C85.9532 205.841 85.7587 203.866 86.1377 201.961C86.5167 200.056 87.4521 198.306 88.8257 196.932C90.1994 195.558 91.9495 194.623 93.8547 194.244C95.76 193.865 97.7349 194.06 99.5296 194.803C101.324 195.546 102.858 196.805 103.938 198.42C105.017 200.036 105.593 201.935 105.593 203.877C105.588 206.481 104.552 208.976 102.711 210.817C100.87 212.658 98.3744 213.694 95.7709 213.699ZM95.7709 199.542C94.9136 199.542 94.0755 199.797 93.3626 200.273C92.6498 200.749 92.0941 201.426 91.7661 202.218C91.438 203.01 91.3521 203.882 91.5194 204.723C91.6866 205.564 92.0995 206.336 92.7057 206.942C93.312 207.549 94.0844 207.962 94.9252 208.129C95.7661 208.296 96.6377 208.21 97.4298 207.882C98.2219 207.554 98.8989 206.998 99.3752 206.286C99.8515 205.573 100.106 204.735 100.106 203.877C100.106 202.728 99.649 201.625 98.8361 200.812C98.0231 199.999 96.9206 199.542 95.7709 199.542Z"
        fill="#3E6177"
      />
      <path
        d="M68.3352 190.547C66.406 190.547 64.4957 190.165 62.7145 189.424C60.9332 188.683 59.3161 187.597 57.9562 186.229C56.5962 184.861 55.5203 183.237 54.7904 181.451C54.0604 179.665 53.6909 177.752 53.7029 175.823L54.1053 96.1137C54.1053 92.233 55.6469 88.5112 58.391 85.7672C61.1351 83.0231 64.8569 81.4814 68.7376 81.4814L214.091 80.7133C217.972 80.7133 221.694 82.2549 224.438 84.999C227.182 87.743 228.723 91.4648 228.723 95.3456V175.787C228.723 179.667 227.182 183.389 224.438 186.133C221.694 188.877 217.972 190.419 214.091 190.419L68.3718 190.51L68.3352 190.547ZM214.055 86.2918L68.7193 86.9686C66.2939 86.9686 63.9677 87.9321 62.2527 89.6471C60.5376 91.3622 59.5741 93.6883 59.5741 96.1137L59.19 175.841C59.19 178.267 60.1535 180.593 61.8686 182.308C63.5837 184.023 65.9098 184.987 68.3352 184.987L214.055 184.895C216.48 184.895 218.806 183.932 220.521 182.217C222.236 180.502 223.2 178.175 223.2 175.75V95.4553C223.2 93.0298 222.236 90.7037 220.521 88.9887C218.806 87.2736 216.48 86.3101 214.055 86.3101V86.2918Z"
        fill="#3E6177"
      />
      <path
        d="M141.277 157.641C138.671 157.647 136.088 157.136 133.68 156.137C131.272 155.139 129.086 153.673 127.249 151.824L79.4924 104.086L83.37 100.209L131.126 147.947C133.819 150.638 137.47 152.151 141.277 152.151C145.085 152.151 148.736 150.638 151.428 147.947L199.185 100.191L203.062 104.068L155.306 151.824C153.468 153.673 151.282 155.139 148.874 156.137C146.466 157.136 143.884 157.647 141.277 157.641Z"
        fill="#3E6177"
      />
      <path d="M72.9258 167.644L108.254 127.346L112.38 130.963L77.0518 171.261L72.9258 167.644Z" fill="#3E6177" />
      <path d="M170.176 130.944L174.302 127.328L209.623 167.632L205.497 171.248L170.176 130.944Z" fill="#3E6177" />
      <path d="M210.799 90.4225H214.457V110.249H210.799V90.4225Z" fill="white" />
      <path d="M210.799 113.082H214.457V121.587H210.799V113.082Z" fill="white" />
      <path d="M210.799 124.406H214.457V127.241H210.799V124.406Z" fill="white" />
      <path d="M213.415 99.5481H217.073V122.759H213.415V99.5481Z" fill="white" />
      <path d="M213.415 126.069H217.073V136.019H213.415V126.069Z" fill="white" />
      <path d="M213.415 139.33H217.073V142.641H213.415V139.33Z" fill="white" />
      <path
        d="M212.134 143.884C238.57 143.884 260 122.454 260 96.0182C260 69.5826 238.57 48.1523 212.134 48.1523C185.699 48.1523 164.268 69.5826 164.268 96.0182C164.268 122.454 185.699 143.884 212.134 143.884Z"
        fill="white"
      />
      <path
        d="M213.798 134.854C235.244 134.854 252.629 117.469 252.629 96.0231C252.629 74.5776 235.244 57.1926 213.798 57.1926C192.353 57.1926 174.968 74.5776 174.968 96.0231C174.968 117.469 192.353 134.854 213.798 134.854Z"
        fill="#F5A8A0"
      />
      <path
        d="M212.134 137.594C203.912 137.594 195.874 135.156 189.037 130.588C182.2 126.02 176.871 119.527 173.725 111.93C170.578 104.333 169.755 95.9743 171.359 87.9098C172.963 79.8452 176.923 72.4374 182.737 66.6232C188.551 60.8089 195.959 56.8494 204.023 55.2453C212.088 53.6411 220.447 54.4644 228.044 57.6111C235.64 60.7577 242.133 66.0863 246.702 72.9232C251.27 79.76 253.708 87.7979 253.708 96.0205C253.694 107.042 249.309 117.608 241.515 125.402C233.722 133.195 223.156 137.58 212.134 137.594ZM212.134 59.9153C204.997 59.9153 198.02 62.0317 192.085 65.997C186.151 69.9623 181.525 75.5983 178.794 82.1923C176.063 88.7863 175.348 96.0422 176.741 103.042C178.133 110.043 181.57 116.473 186.617 121.519C191.664 126.566 198.094 130.003 205.094 131.396C212.094 132.788 219.35 132.073 225.944 129.342C232.538 126.611 238.174 121.985 242.139 116.051C246.105 110.117 248.221 103.139 248.221 96.0022C248.202 86.4389 244.393 77.2732 237.629 70.5127C230.865 63.7522 221.697 59.948 212.134 59.9335V59.9153Z"
        fill="#3E6177"
      />
      <path
        d="M208.622 113.358C207.902 113.356 207.2 113.133 206.61 112.718L192.582 102.896C191.985 102.479 191.579 101.842 191.452 101.125C191.325 100.408 191.488 99.6701 191.905 99.0734C192.322 98.4768 192.959 98.0703 193.676 97.9434C194.393 97.8165 195.131 97.9795 195.728 98.3967L208.202 107.085L228.559 79.9417C228.78 79.6535 229.056 79.4116 229.37 79.2299C229.684 79.0481 230.032 78.9301 230.392 78.8826C230.752 78.835 231.118 78.8588 231.468 78.9527C231.819 79.0466 232.148 79.2086 232.437 79.4296C232.725 79.6506 232.967 79.9261 233.148 80.2406C233.33 80.5551 233.448 80.9022 233.496 81.2623C233.543 81.6224 233.519 81.9883 233.426 82.3391C233.332 82.69 233.17 83.0189 232.949 83.3071L211.439 111.932C211.114 112.373 210.689 112.732 210.2 112.979C209.711 113.227 209.171 113.357 208.622 113.358Z"
        fill="white"
      />
    </svg>
  );
}

export default MarkEmailRead;
