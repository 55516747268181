import React from 'react';

export default function Logo({ className }: { className: string }) {
  return (
    <svg className={className} viewBox="0 0 188 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66.8763 18.6947C66.8763 14.2307 64.5147 12.2435 58.6683 12.2435H52.7931V33.5266H54.9243V24.9155H59.0139C64.8027 24.9155 66.8763 22.7267 66.8763 18.6947ZM64.7163 18.6947C64.7163 21.7763 63.1035 22.9859 58.8699 22.9859H54.9243V14.1731H57.9483C62.0091 14.1731 64.7163 14.3747 64.7163 18.6947Z"
        fill="currentColor"
      />
      <path
        d="M76.0576 19.5011V17.3987C73.7824 17.5139 72.4288 18.2915 71.5936 20.0195V17.7731H69.4624V33.5266H71.5936V24.3395C71.5936 21.4019 73.0912 19.7891 76.0576 19.5011Z"
        fill="currentColor"
      />
      <path
        d="M93.093 25.6931C93.093 20.8835 89.637 17.3987 84.9138 17.3987C80.2482 17.3987 76.6482 20.9411 76.6482 25.5491C76.6482 30.3874 80.1906 33.901 85.0866 33.901C89.6946 33.901 93.093 30.4162 93.093 25.6931ZM90.933 25.7219C90.933 29.3218 88.4562 31.9426 85.029 31.9426C81.4002 31.9426 78.8082 29.293 78.8082 25.6067C78.8082 22.0355 81.429 19.3571 84.9138 19.3571C88.3698 19.3571 90.933 22.0643 90.933 25.7219Z"
        fill="currentColor"
      />
      <path
        d="M104.707 29.005C104.707 27.0754 103.814 25.7507 101.971 24.9731C99.5809 23.9939 99.4657 23.9363 99.0049 23.7059C98.0257 23.2163 97.5649 22.5539 97.5649 21.6323C97.5649 20.3651 98.5729 19.3571 99.8401 19.3571C101.222 19.3571 102.144 20.1635 102.144 21.6611H104.304C104.304 19.1267 102.403 17.3987 99.8689 17.3987C97.3057 17.3987 95.4049 19.2707 95.4049 21.8339C95.4049 22.9571 95.8081 23.9363 96.5857 24.6563C97.2193 25.2611 97.2769 25.2899 99.4945 26.1827C101.77 27.1042 102.547 27.6226 102.547 29.2066C102.547 30.7906 101.424 31.9426 99.8977 31.9426C98.1409 31.9426 96.9313 30.8194 96.9313 28.8898H94.8289C94.8289 31.9426 96.8737 33.901 99.8977 33.901C102.72 33.901 104.707 31.885 104.707 29.005Z"
        fill="currentColor"
      />
      <path
        d="M123.908 25.6931C123.908 20.9987 120.308 17.3987 115.614 17.3987C112.935 17.3987 110.746 18.4931 109.422 20.4803V17.7731H107.29V39.0562H109.422V30.8194C110.804 32.8354 112.935 33.901 115.556 33.901C120.25 33.901 123.908 30.301 123.908 25.6931ZM121.748 25.6931C121.748 29.2354 119.07 31.9426 115.556 31.9426C111.956 31.9426 109.278 29.2066 109.278 25.5779C109.278 22.0931 112.042 19.3571 115.527 19.3571C119.07 19.3571 121.748 22.0643 121.748 25.6931Z"
        fill="currentColor"
      />
      <path
        d="M142.704 26.3555C142.704 20.7683 139.162 17.3987 134.496 17.3987C129.917 17.3987 126.317 20.9987 126.317 25.5779C126.317 30.2434 129.975 33.901 134.64 33.901C138.211 33.901 141.293 31.3378 142.301 28.3426H140.083C139.19 30.5026 136.973 31.9426 134.583 31.9426C131.357 31.9426 128.621 29.4658 128.448 26.3555H142.704ZM140.573 24.4259H128.506C129.082 21.4019 131.472 19.3571 134.467 19.3571C137.52 19.3571 139.853 21.2867 140.573 24.4259Z"
        fill="currentColor"
      />
      <path
        d="M152.248 19.5011V17.3987C149.973 17.5139 148.619 18.2915 147.784 20.0195V17.7731H145.653V33.5266H147.784V24.3395C147.784 21.4019 149.282 19.7891 152.248 19.5011Z"
        fill="currentColor"
      />
      <path
        d="M181.683 33.5266L172.755 12.2435H169.645L160.774 33.5266H165.123L167.485 28.0258H175.059L177.334 33.5266H181.683ZM173.475 24.2531H168.925L171.2 17.8019L173.475 24.2531Z"
        fill="currentColor"
      />
      <path d="M187.796 33.5266V12.2435H183.85V33.5266H187.796Z" fill="currentColor" />
      <path
        d="M34.8751 8.21605C33.976 6.96684 32.8877 5.81226 31.5911 4.79964C24.8529 -0.452743 15.6636 -0.32025 9.17151 5.10248C8.04533 6.03939 7.08949 7.08987 6.28507 8.21605C2.61313 13.317 2.16834 19.9227 4.32607 24.5883C2.7267 17.1025 5.40494 11.623 9.05795 8.21605C9.87183 7.45895 10.733 6.80595 11.6037 6.24759C17.2346 2.69869 24.711 2.83118 30.0012 6.51258C30.7583 7.03308 31.4492 7.60091 32.0927 8.21605C36.4839 12.3706 38.2536 18.3233 36.8246 24.5505C39.0202 19.6104 38.4429 13.1656 34.8656 8.21605H34.8751Z"
        fill="currentColor"
      />
      <path
        d="M13.7522 11.3015C13.7712 12.5034 13.3642 15.3236 14.0078 16.6201C14.6797 17.9829 15.3138 18.598 16.8469 18.0302H16.8374C18.5788 17.1122 18.0393 15.1816 17.1308 14.0176C16.298 12.9482 15.4746 12.7305 13.7522 11.3015Z"
        fill="currentColor"
      />
      <path
        d="M20.4342 19.6103H20.4436C21.2481 17.4336 21.8348 15.4557 21.513 13.544C22.6581 15.0582 22.0619 17.339 21.4657 19.6292C24.4941 19.3169 24.8253 16.2412 24.1818 14.0456C23.6045 12.0393 22.554 11.2538 20.8979 8.21594C20.2449 9.94781 18.4941 13.0898 18.6171 15.3327C18.7685 17.992 18.8632 18.5504 20.4342 19.6103Z"
        fill="currentColor"
      />
      <path
        d="M25.9416 15.2283C27.3044 15.7299 27.8628 15.181 28.459 13.9696C29.0268 12.815 28.6577 10.3071 28.6861 9.24719C27.153 10.5153 26.4243 10.7046 25.6861 11.6604C24.8722 12.7015 24.3896 14.4144 25.9416 15.2283Z"
        fill="currentColor"
      />
      <path
        d="M32.2823 21.5407C31.2413 21.8057 30.0773 22.6574 30.664 23.954C31.2792 24.9571 31.9132 24.8909 32.9069 24.4082C33.8533 23.954 34.8659 22.1653 35.4054 21.4934C33.7871 21.5407 33.2287 21.3041 32.2823 21.5407Z"
        fill="currentColor"
      />
      <path
        d="M31.5536 14.3107C29.623 15.011 27.541 15.7775 25.7524 17.4999C23.6325 19.5441 23.5284 20.3864 23.7744 22.459C26.1782 21.5599 27.2571 19.8186 28.6293 18.1719C28.1845 20.2255 26.6987 22.0142 24.3423 23.1593C26.7839 25.4779 29.4527 23.358 30.8439 21.2381C32.112 19.3075 31.5347 17.6608 31.5536 14.3107Z"
        fill="currentColor"
      />
      <path
        d="M9.61653 16.1659C9.63546 19.5161 9.05817 21.1628 10.3263 23.0934C11.7175 25.2133 14.3768 27.3237 16.8279 25.0145C14.4714 23.8694 12.9856 22.0808 12.5408 20.0271C13.9131 21.6738 14.9919 23.4152 17.3957 24.3142C17.6418 22.2322 17.5377 21.3899 15.4178 19.3552C13.6292 17.6328 11.5471 16.8663 9.61653 16.1659Z"
        fill="currentColor"
      />
      <path
        d="M9.00159 24.1061C8.05522 23.8695 7.48739 24.1061 5.87856 24.0588C6.42745 24.7307 7.44008 26.5194 8.37699 26.9736C9.36122 27.4468 9.99529 27.5225 10.6199 26.5194C11.2066 25.2229 10.0426 24.3711 9.00159 24.1061Z"
        fill="currentColor"
      />
      <path
        d="M37.383 9.3427C39.5502 13.573 40.3168 17.9735 39.2474 22.6297C38.1591 27.3521 35.6039 31.1376 31.6386 33.9483C29.1591 35.7086 26.3199 36.7969 23.4051 37.2133C23.5092 35.2827 23.566 33.0966 23.3483 31.4688C26.5376 29.1786 28.0423 25.6108 28.1275 24.513C23.0928 31.4878 18.5786 30.4751 12.1906 26.8411C13.9792 29.0177 15.3988 30.3048 19.4871 31.7906C19.6196 33.3143 19.241 35.3206 18.5975 37.2796C15.342 36.9294 12.1622 35.7654 9.4366 33.778C6.12429 31.3742 3.80567 28.2322 2.53753 24.371C0.843515 19.1849 1.43973 14.1786 3.9287 9.33313C-1.32368 16.0619 -1.49403 27.7496 6.48391 35.3301C14.2726 42.7307 26.689 42.8348 34.5912 35.5383C42.7869 27.9767 42.6828 16.1565 37.3831 9.33313L37.383 9.3427Z"
        fill="currentColor"
      />
      <path
        d="M20.6801 24.1156C19.5823 24.3144 18.8442 25.3743 19.0429 26.4721C19.2416 27.5699 20.3016 28.3081 21.3994 28.1094C22.4972 27.9106 23.2354 26.8507 23.0366 25.7529C22.8379 24.6551 21.7779 23.9169 20.6801 24.1156Z"
        fill="currentColor"
      />
    </svg>
  );
}
