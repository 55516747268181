import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

function PageTracker({ children }: { children: ReactNode }) {
  const location = useLocation();
  useEffect(() => {
    Mixpanel.track(MixpanelEventType.LOCATION_CHANGE, {
      path: location.pathname,
    });
  }, [location]);

  return <>{children}</>;
}

export default PageTracker;
