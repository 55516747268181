import React from 'react';
import clsx from 'clsx';

type Props = {
  className: string;
};

function CheckMarkCircle({ className }: Props) {
  return (
    <svg className={clsx(className)} viewBox="0 0 262 223" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="130.812" cy="107.752" r="82.6221" fill="#EEF4FB" />
      <path
        d="M130.812 18.3822C181.104 18.3822 221.871 59.1493 221.871 109.441C221.871 159.733 181.104 200.5 130.812 200.5C80.5204 200.5 39.7533 159.733 39.7533 109.441C39.7533 59.1493 80.5204 18.3822 130.812 18.3822ZM130.812 29.7646C86.808 29.7646 51.1357 65.4369 51.1357 109.441C51.1357 153.445 86.808 189.118 130.812 189.118C174.816 189.118 210.489 153.445 210.489 109.441C210.489 65.4369 174.816 29.7646 130.812 29.7646ZM170.122 80.3751C170.651 80.9036 171.07 81.5311 171.356 82.2217C171.643 82.9123 171.79 83.6524 171.79 84.3999C171.79 85.1475 171.643 85.8876 171.356 86.5782C171.07 87.2688 170.651 87.8963 170.122 88.4248L120.04 138.507C119.511 139.036 118.884 139.455 118.193 139.741C117.503 140.027 116.763 140.175 116.015 140.175C115.268 140.175 114.527 140.027 113.837 139.741C113.146 139.455 112.519 139.036 111.99 138.507L91.5021 118.019C90.4347 116.951 89.835 115.504 89.835 113.994C89.835 112.485 90.4347 111.037 91.5021 109.969C92.5695 108.902 94.0173 108.302 95.5269 108.302C97.0365 108.302 98.4843 108.902 99.5517 109.969L116.015 126.433L162.073 80.3751C162.601 79.8465 163.229 79.4272 163.919 79.1411C164.61 78.855 165.35 78.7077 166.098 78.7077C166.845 78.7077 167.585 78.855 168.276 79.1411C168.966 79.4272 169.594 79.8465 170.122 80.3751V80.3751Z"
        fill="#3E6177"
      />
      <path
        opacity="0.15"
        d="M179.147 27.4345L36.7105 133.211C16.4359 148.267 10.2455 174.269 22.8837 191.287C35.522 208.306 62.2031 209.896 82.4777 194.84L224.914 89.0638C245.188 74.0075 251.379 48.0058 238.741 30.9873C226.102 13.9688 199.421 12.3782 179.147 27.4345Z"
        fill="#F7AFA3"
      />
      <path
        d="M214.584 48.0014C212.407 47.1223 210.54 45.6175 209.218 43.6771C207.896 41.7368 207.179 39.448 207.158 37.1004C207.137 34.7527 207.813 32.4516 209.1 30.4879C210.387 28.5242 212.227 26.9863 214.388 26.0685C216.549 25.1507 218.934 24.8943 221.24 25.3318C223.547 25.7692 225.672 26.8808 227.347 28.526C229.022 30.1713 230.171 32.2762 230.65 34.5747C231.128 36.8731 230.914 39.2619 230.035 41.4389C228.853 44.3549 226.562 46.6831 223.666 47.9133C220.77 49.1434 217.504 49.1751 214.584 48.0014ZM221.432 31.0414C220.261 30.5687 218.977 30.4538 217.741 30.7111C216.505 30.9685 215.373 31.5866 214.488 32.4872C213.604 33.3879 213.006 34.5307 212.771 35.7711C212.535 37.0115 212.673 38.2938 213.167 39.4558C213.66 40.6178 214.487 41.6074 215.543 42.2995C216.599 42.9915 217.837 43.3548 219.099 43.3435C220.362 43.3322 221.592 42.9469 222.636 42.2361C223.679 41.5253 224.488 40.5211 224.961 39.3505C225.584 37.7814 225.562 36.0296 224.9 34.4766C224.238 32.9235 222.989 31.695 221.425 31.0584L221.432 31.0414Z"
        fill="#F5A8A0"
      />
      <path
        d="M215.341 63.6062C217.336 64.4118 219.607 63.4475 220.412 61.4524C221.218 59.4573 220.254 57.1869 218.259 56.3813C216.263 55.5757 213.993 56.54 213.187 58.5351C212.382 60.5302 213.346 62.8006 215.341 63.6062Z"
        fill="#F5A8A0"
      />
      <path
        d="M57.1011 171.565C55.0151 172.092 53.7516 174.211 54.279 176.297C54.8064 178.383 56.925 179.646 59.011 179.119C61.0969 178.591 62.3604 176.473 61.833 174.387C61.3057 172.301 59.1871 171.037 57.1011 171.565Z"
        fill="#D5DFEB"
      />
      <path
        d="M36.0061 164.464C37.8895 163.988 39.8717 164.081 41.7022 164.732C43.5326 165.382 45.1291 166.561 46.2898 168.118C47.4504 169.676 48.123 171.543 48.2226 173.483C48.3222 175.423 47.8443 177.349 46.8492 179.018C45.8542 180.686 44.3868 182.022 42.6325 182.856C40.8783 183.691 38.916 183.986 36.9938 183.705C35.0716 183.425 33.2758 182.58 31.8336 181.279C30.3914 179.977 29.3674 178.277 28.8913 176.394C28.2578 173.869 28.6509 171.196 29.9845 168.96C31.318 166.724 33.4833 165.107 36.0061 164.464ZM39.4761 178.189C40.3073 177.979 41.0575 177.527 41.6319 176.891C42.2062 176.254 42.579 175.462 42.7029 174.613C42.8268 173.765 42.6964 172.899 42.3281 172.125C41.9599 171.35 41.3703 170.703 40.634 170.264C39.8976 169.824 39.0476 169.614 38.1914 169.657C37.3352 169.701 36.5112 169.998 35.8237 170.511C35.1362 171.023 34.616 171.727 34.329 172.535C34.0419 173.343 34.0009 174.218 34.211 175.049C34.4928 176.164 35.2058 177.121 36.1932 177.71C37.1806 178.298 38.3615 178.471 39.4761 178.189Z"
        fill="#3E6177"
      />
    </svg>
  );
}

export default CheckMarkCircle;
