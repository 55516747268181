import axios from 'axios';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { IUser } from '../models/user';
import { Mixpanel } from '../services/mixpanel';

interface IAuthContext {
  user?: IUser;
  signin: (user: IUser) => void;
  signout: () => void;
}

export const UserContext = createContext<IAuthContext>(null!);
UserContext.displayName = 'UserContext';

export function useAuth() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser>();
  const signin = (newUser: IUser) => {
    setUser(newUser);
    Mixpanel.identify(newUser.id);
    Mixpanel.people.set({
      plan: newUser.organization.plan_sku,
      organization: newUser.organization.name,
      $first_name: newUser.first_name,
      $last_name: newUser.last_name,
      $email: newUser.email,
    });
  };
  const signout = () => {
    axios.delete('/recruiters/sign_out').finally(() => {
      setUser(undefined);
      Mixpanel.reset();
    });
  };
  const userValue = { user, signin, signout };

  return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>;
};
